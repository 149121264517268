import { defineComponent, reactive } from '@vue/composition-api';
import { usePhoneValidityQuery } from '@/generated-types/graphql.types';
export default defineComponent({
    props: {
        phone: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { emit } = context;
        const queryVariables = reactive({
            input: props.phone
        });
        const { onResult } = usePhoneValidityQuery(queryVariables);
        onResult((result) => {
            const isPhoneValid = result.data?.phone_validity.is_valid;
            emit('validated', isPhoneValid);
        });
        return {};
    }
});
